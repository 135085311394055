<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ taskId ? 'Update' : 'Add' }} Task
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  Task manager
                </div>
              </div>
            </div>
          </div>
          <v-card>
            <v-card-title class="d-flex justify-content-between">
              <v-sheet @click="editedTask.title =!editedTask.title" v-if="!editedTask.title">
                {{task.title}} <v-btn icon>
                <v-icon x-small>
                  fas fa-edit
                </v-icon>
              </v-btn>
              </v-sheet>
              <v-text-field @focusout="editedTask.title =!editedTask.title" dense outlined v-else
                            v-model="task.title">

              </v-text-field>
              <span class="text-danger" v-if="$v.task.title.$error">Title is Required</span>
              <v-col cols="12" md="6" >
                <v-select
                    v-if="currentUser && currentUser.access_type == 'head'"
                    outlined
                    dense
                    :items="ourBranches"
                    item-value="id"
                    item-text="name"
                    v-model="task.branch_id"
                    label="Branch"
                >
                </v-select>
              </v-col>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <div class="text-subtitle-2 mb-2">Members</div>
                  <div class="my-2">

                    <v-btn @click="editedTask.assigned_to =!editedTask.assigned_to" color="blue" dark fab
                           rounded
                           x-small>
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <v-autocomplete
                        :items="admin_users"
                        :loading="isBusy"
                        :search-input.sync="searchName"
                        clearable
                        deletable-chips
                        small-chips
                        dense
                        item-text="full_name"
                        item-value="id"
                        label="Assign To User CRN or Email"
                        multiple
                        outlined
                        class="mt-2"
                        v-model="assigned_to"
                    >
                    </v-autocomplete>

                  </div>
                  <div class="my-2">

                    <v-file-input
                        clearable
                        dense
                        label="File"
                        outlined
                        class="mt-2"
                        v-model="task.file"
                        prepend-inner-icon="mdi-file"
                        prepend-icon=""
                    >
                    </v-file-input>

                    <v-img v-if="task.file_path" :src="task.file_path.real" max-width="150" max-height="150"></v-img>
                  </div>
                  <div class="my-2">
                    <span class="font-weight-bold text-subtitle-1">Description</span>
                    <v-btn @click="editedTask.description =!editedTask.description" class="px-2 ml-2"
                           depressed
                           small>Edit
                    </v-btn>

                    <div>
                      <v-sheet @click="editedTask.description =!editedTask.description"
                               v-if="!editedTask.description">
                        <p class="ml-4" v-html="task.description"></p>
                      </v-sheet>

                      <ckeditor :config="editorConfig"
                                @focusout="editedTask.description =!editedTask.description"
                                class="mt-2" v-if="editedTask.description"
                                v-model="task.description"></ckeditor>
                      <span class="text-danger"
                            v-if="$v.task.description.$error">Description is Required</span>

                    </div>

                    <!--                                <div class="my-2" v-if="task.id">-->
                    <!--                                         <span class="font-weight-bold text-subtitle-1">-->
                    <!--                                       <v-icon>-->
                    <!--                                       fas fa-check-square-->
                    <!--                                    </v-icon>-->
                    <!--                                    Attachments</span>-->
                    <!--                                    <v-btn @click="addChecklist =true" class="px-2 ml-2"-->
                    <!--                                           depressed-->
                    <!--                                           small>Add-->
                    <!--                                    </v-btn>-->
                    <!--                                    <v-sheet></v-sheet>-->
                    <!--                                </div>-->
                    <div class="my-2" v-if="task.id">
                                         <span class="font-weight-bold text-subtitle-1">
                                       <v-icon>
                                       fas fa-check-square
                                    </v-icon>
                                    Checklist</span>
                      <v-btn @click="addChecklist =true" class="px-2 ml-2"
                             depressed
                             small>Add
                      </v-btn>
                    </div>
                    <v-text-field @click:append="__addList" @keyup.enter="__addList"
                                  append-icon="fas fa-user-edit" class="my-2"
                                  dense
                                  outlined
                                  :error="$v.checklistData.checklist.$error"
                                  label="Add checklist"
                                  v-if="addChecklist"
                                  v-model="checklistData.checklist">

                    </v-text-field>
                    <div v-if="checklists.length">

                      <v-progress-linear dark
                                         height="10"
                                         v-model="completed_percentage"
                      >
                        <strong> {{completed_percentage.toFixed(2)}}%</strong>
                      </v-progress-linear>
                    </div>
                    <v-list dense flat v-if="checklists.length">
                      <v-list-item-group

                          color="primary"
                      >
                        <v-list-item
                            :key="i"
                            v-for="(item, i) in checklists"
                        >
                          <v-list-item-icon>
                            <v-checkbox @change="toogleChecklist(item)"
                                        v-model="item.is_complete"></v-checkbox>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                                :class="item.is_complete ? 'text-decoration-line-through' : '' "
                                v-text="item.checklist"></v-list-item-title>

                          </v-list-item-content>
                          <v-btn class="float-right" icon @click="editChecklist(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <br>
                    <span class="font-weight-bold text-subtitle-1">
                                    Comments
                                </span>
                    <template v-if="comments.length">
                      <div :key="index"
                           class="d-flex justify-content-start mb-2 align-items-center"
                           v-for="(comment,index) in comments">
                        <v-avatar class="mx-1" size="30">
                          <img
                              :alt="comment.added_by"
                              :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${comment.added_by}`"
                          >
                        </v-avatar>

                        <span>
                                            <strong>
                                                {{comment.added_by}}
                                            </strong>
                                            <br>
                                            <span>
                                            {{ comment.posted_at }}
                                        </span>

                                        </span>

                        <p class="m-0 ml-3">
                          {{comment.comment}}
                        </p>

                      </div>
                    </template>

                    <div class="d-flex justify-content-between">

                      <v-avatar class="mx-1" size="30">
                        <img
                            :alt="currentUser.full_name"
                            :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${currentUser.full_name}`"
                        >
                      </v-avatar>

                      <v-text-field @click:append="__addComment()" @keyup.enter="__addComment()"
                                    append-icon="fas fa-user-edit" dense hint="Press Enter to post"
                                    outlined
                                    :error="$v.commentData.comment.$error"
                                    persistent-hint
                                    placeholder="Press Enter to post" v-model="commentData.comment">

                      </v-text-field>

                    </div>


                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-subtitle-2 font-weight-bold mb-2 text-h2">Status</div>
                  <v-btn :color="task.status == 'pending' ? 'yellow' : '' " :outlined="task.status == 'pending'"
                         @click="task.status = 'pending'" block
                         class="my-1"
                         depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                      <span>Pending</span>
                    </div>
                  </v-btn>
                  <v-btn :color="task.status == 'process' ? 'blue' : '' " :outlined="task.status == 'process'"
                         @click="task.status = 'process'" block
                         class="my-1"
                         depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                      <span>In Progress</span>
                    </div>
                  </v-btn>
                  <v-btn :color="task.status == 'complete' ? 'green' : '' "
                         :outlined="task.status == 'complete'" @click="task.status = 'complete'" block
                         class="my-1"
                         depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>fas fa-check</v-icon>
                      <span>Completed</span>
                    </div>
                  </v-btn>
                  <div class="text-subtitle-2 font-weight-bold mb-2 text-h2">Priority</div>
                  <v-btn :color="task.priority == 'high' ? 'red' : '' " :dark="task.priority == 'high'"
                         @click="task.priority = 'high'" block
                         class="my-1" depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                      <span>Important</span>
                    </div>
                  </v-btn>
                  <v-btn :color="task.priority == 'normal' ? 'green' : '' " :dark="task.priority == 'normal'"
                         @click="task.priority = 'normal'" block
                         class="my-1" depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                      <span>Normal</span>
                    </div>
                  </v-btn>
                  <div class="text-subtitle-2 font-weight-bold mb-2 text-h2 my-3">Action</div>
                  <v-btn @click="redirectToApplicant()" block class="my-1" depressed v-if="task.applicant_id">
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>mdi-eye</v-icon>
                      <span>View Applicant</span>
                    </div>
                  </v-btn>
                  <v-autocomplete
                      :items="applicant_users"
                      :loading="isBusy"
                      :search-input.sync="searchName"
                      class="mt-4"
                      clearable
                      dense
                      item-text="display_text"
                      item-value="id"
                      label="Applicant Name or Email"
                      v-model="task.applicant_id"
                  >
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.first_name }} - {{ data.item.last_name }}- {{ data.item.email }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.first_name }} - {{ data.item.last_name }}- {{ data.item.email }}
                    </template>
                  </v-autocomplete>
                  <v-btn @click="showDeadline=true" block class="my-1" depressed>
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>fas fa-calendar</v-icon>
                      <span>Deadline</span>
                    </div>
                  </v-btn>
                  <v-sheet v-if="showDeadline">
                    <v-row class="mt-2">
                      <v-col cols="12">
                        <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                            v-model="menu2"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dense
                                hide-details
                                label="Deadline Date"
                                outlined
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-model="task.due_date"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              :min="today"
                              @input="menu2 = false"
                              v-model="task.due_date"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col class="mb-2" cols="6">
                        <vue-timepicker :format="time_format" manual-input
                                        v-model="task.due_time"></vue-timepicker>
                      </v-col>
                      <v-col class="mb-2" cols="6">
                        <v-checkbox label="reminder" v-model="task.reminder"></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-sheet>

                  <v-btn v-if="task.id && checkIsAccessible('task-manager', 'edit')" @click="__delete(task.id)" block class="my-1" color="red" dark depressed
                  >
                    <div class="d-flex justify-content-between">
                      <v-icon class="mr-4" x-small>fas fa-trash</v-icon>
                      <span>Delete</span>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="cancel"
                  color="mr-2" text dark
                  medium>
                Cancel
              </v-btn>
              <v-btn v-if="checkIsAccessible('task-manager', 'create')"
                     :loading="loading"
                     @click="createOrUpdate()"
                     class="btn btn-primary"
                     medium
              >
                {{taskId ? "Update" : "Save"}}
              </v-btn>
            </v-card-actions>
            <v-snackbar
                :timeout="timeout"
                absolute
                bottom
                right
                v-model="snackbar"
            >
                  <span class="text-white">
                        {{ text }}
                  </span>


            </v-snackbar>
          </v-card>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import 'vue2-timepicker/dist/VueTimepicker.css';
import TaskService from "@/services/task-manager/task/TaskService";
import {required} from "vuelidate/lib/validators";
import GeneralMixin from "@/mixins/GeneralMixin";
import TaskChecklistService from "@/services/task-manager/checklist/TaskChecklistService";
import TaskCommentService from "@/services/task-manager/comment/TaskCommentService";
import AdminUserService from "@/services/admin-user/AdminUserService";

const adminUser = new AdminUserService;
const taskService = new TaskService();
const taskChecklistService = new TaskChecklistService();
const taskCommentService = new TaskCommentService();

export default {
    name: "TaskDetail",
    mixins: [GeneralMixin],
    components: {
        VueTimepicker,
    },
    watch: {
        isUpdating(val) {
            if (val) {
                setTimeout(() => (this.isUpdating = false), 3000)
            }
        },
        searchName(newValue, oldValue) {
            this.applicant_search.name = newValue;
            // Lazily load input items
            this.getAllApplicantUsers();

        },
    },
    validations: {
        task: {
            title: {required},
            description: {required},
        },
        checklistData: {
          checklist: { required }
        },
        commentData: {
          comment: { required }
        }
    },
    data() {
        return {
          today: new Date().toISOString().substr(0, 10),
          editorConfig: {
              versionCheck: false,
              toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord']],
          },
          loading: false,
          searchName: null,
          isUpdating: false,
          isBusy: false,
          currentDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time_format: 'hh:mm',
          menu2: false,
          dialog: false,
          showDeadline: false,
          addChecklist: false,
          snackbar: false,
          text: '',
          timeout: 2000,
          task: {
              title: 'Task',
              description: "Description ",
              due_date: null,
              due_time: "",
              reminder: 0,
              applicant_id: null,
              assigned_to: [],
              priority: 'normal',
              status: 'pending',
              is_active: 1,
          },
          editedTask: {
              title: true,
              description: true,
              assigned_to: false,
          },
          checklistData: {
              checklist: null,
              is_complete: false
          },
          commentData: {
              comment: null,
              task_id: null,
              applicant_id: null,

          },
          checklists: [],
          comments: [],
          admin_users: [],
          assigned_to: [],
        }
    },
    computed: {
        completed_percentage() {
            if (this.checklists.length) {
                let total_done = 0;
                let data = this.checklists.filter((item) => {
                    if (item.is_complete)
                        total_done++;
                })
                return (total_done / this.checklists.length) * 100;
            }
            return 0;
        },
        taskId() {
            return this.$route.params.taskId;
        },
    },
    mounted() {
      this.getAllApplicantUsers();
      this.getAllAdminUsers();
      this.taskDetail();
      this.getAllBranches();
    },
    methods: {
        reset() {
            this.assigned_to = [];
            this.task = {
                title: 'Task',
                description: "Description ",
                due_date: null,
                due_time: "",
                reminder: 0,
                applicant_id: null,
                assigned_to: [],
                priority: 'normal',
                status: 'pending',
                is_active: 1,
            }
            this.comments = [];
            this.checklists = [];
        },

        cancel() {
            this.reset();
            this.$tabs.close().then(() => {
              this.$tabs.open({name: 'task-manager'});
            })
        },

        redirectToApplicant() {
            this.$router.push({
                name: 'student-profile',
                params: {user_id: this.task.applicant_id}
            })
        },

        taskDetail() {
            if (this.taskId != null || this.taskId != undefined) {
                taskService
                    .show(this.taskId)
                    .then(response => {
                      if(response.data.task) {
                        this.task = response.data.task;
                        this.editedTask.title = false;
                        this.editedTask.description = false;
                        this.task = response.data.task
                        this.commentData.task_id = this.task.id;
                        this.commentData.applicant_id = this.task.applicant_id ? this.task.applicant_id : null;
                        this.assigned_to = this.task.assigned_to;
                        this.__getCheckList();
                        this.__getComments();
                        if(this.task.due_date) {
                          this.showDeadline = true;
                        }
                      } else {
                        this.$tabs.close().then(() => {
                          this.$tabs.open({ name: '403'})
                        })
                      }
                    })
            }
        },

        convertToFormData() {
          let formData = new FormData();
          for (let key in this.task) {
            if (key === "file" && this.task[key] != null && this.task[key] != undefined) {
              formData.append('file', this.task[key]);
            }else {
              if(this.task[key]){
                formData.append(key,this.task[key]);
              }
            }
          }
          return formData;
        },

        createOrUpdate() {
            this.$v.task.$touch()
            if (this.$v.task.$error) {
              setTimeout(() => {
                this.$v.task.$reset()
              }, 3000);
            } else {
                let formData = this.convertToFormData();
                if (!this.task.id) this._create(formData);
                else this.__update(formData);
            }
        },

        _create: function (formData) {
            this.loading = true;
            taskService
                .create(formData)
                .then(response => {
                    this.loading = false;
                    this.$snotify.success("Task created successfully");
                    this.cancel();
                    this.resetForm();
                    this.errors = [];
                    this.$emit('refresh');
                })
                .catch(error => {
                    this.loading = false;
                    this.errors = error.errors;
                })
        },

        __update: function (formData) {
            this.loading = true;
            taskService
                .update(this.task.id, formData)
                .then(response => {
                    this.loading = false;
                    this.$snotify.success("Task updated successfully");
                    this.cancel();
                    this.resetForm();
                    this.errors = [];
                    this.$emit('refresh');
                })
                .catch(error => {
                    this.loading = false;
                    this.errors = error.errors;
                })
        },

        __delete(id) {
            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        taskService
                            .delete(id)
                            .then((response) => {
                                this.cancel();
                                this.$snotify.success("Task Deleted Successfully ");
                            })
                            .catch((err) => {
                                this.$snotify.error("Oops something went wrong");
                            });
                    }
                },
            });
        },

        //checklist methods
        __getCheckList() {
            taskChecklistService.paginate(this.task_id).then(res => {
                this.checklists = res.data;
            }).catch(err => {
                // console.log(err)
            })
        },

        __addList() {
            this.$v.checklistData.$touch()
            if (this.$v.checklistData.$error) {
              setTimeout(() => {
                this.$v.checklistData.$reset();
              }, 3000);
            } else {
              if(this.checklistData && this.checklistData.id) {
                taskChecklistService.update(this.task_id, this.checklistData.id, this.checklistData).then(res => {
                  this.checklists.push(this.checklistData);
                  this.checklistData = {
                    checklist: null,
                    is_complete: false
                  };
                  this.text = 'Checklist Edited';
                  this.snackbar = true;
                  this.__getCheckList();
                  this.$v.checklistData.$reset();
                  this.addChecklist = false;
                }).catch(err => {});
              } else {
                taskChecklistService.create(this.task_id, this.checklistData).then(res => {
                  this.checklists.push(this.checklistData);
                  this.checklistData = {
                    checklist: null,
                    is_complete: false
                  };
                  this.text = 'Checklist Added';
                  this.snackbar = true;
                  this.__getCheckList();
                  this.$v.checklistData.$reset();
                  this.addChecklist = false;
                }).catch(err => {});
              }
            }
        },

        toogleChecklist(item) {
            taskChecklistService.update(this.task_id, item.id, item).then(res => {
                this.text = item.is_complete ? 'Task Completed' : 'Task Uncompleted';
                this.snackbar = true;
            }).catch(error => {
                this.$snotify.error('Something  Wrong');
            })
        },

        editChecklist(item) {
          this.addChecklist = true;
          this.checklistData = item;
        },

        __removeTask(index) {
            this.checklists.splice(index, 1)
        },

        // comments methods
        __getComments() {
            taskCommentService.paginate(this.task_id).then(res => {
                this.comments = res.data;
            }).catch(err => {
                // console.log(err)
            })
        },

        __addComment() {
            this.$v.commentData.$touch()
            if (this.$v.commentData.$error) {
              setTimeout(() => {
                this.$v.commentData.$reset();
              }, 3000);
            } else {
              taskCommentService.create(this.task_id, this.commentData).then(res => {
                this.__getComments();
                this.commentData = {
                  comment: null,
                  task_id: null,
                  applicant_id: null,

                };
                this.$v.commentData.$reset();
              }).catch(err => {
                // console.log(err)
              })
            }
        },
        // comments methods end

        remove(item) {
            const index = this.friends.indexOf(item.name)
            if (index >= 0) this.friends.splice(index, 1)
        },

        getAllAdminUsers() {
            adminUser
                .all()
                .then(response => {
                    this.admin_users = response.data.adminUsers;
                })
                .catch(err => {
                    // console.log(err)
                })
        }
    },

}
</script>

<style scoped>

    .vue__time-picker {
        display: initial !important;
    }

</style>
